<template>
    <el-dialog
            :visible.sync="visible"
            append-to-body
            width="950px"
            custom-class="cropperDialog"
            :before-close="onClose">
        <div class="cropper-content"
             v-loading="loading"
             element-loading-background="rgba(0, 0, 0, 0.2)">
            <!-- 剪裁框 -->
            <div class="cropper">
                <VueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType"
                            :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox"
                            :original="option.original" :autoCrop="option.autoCrop" :mode="option.mode"
                            :centerBox="option.centerBox"
                            :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight"
                            :fixedBox="option.fixedBox" @realTime="realTime" :fixed="option.fixed"
                            :fixedNumber="fixedNumber" @imgLoad="imgLoad"
                ></VueCropper>
            </div>
            <!-- 预览框 -->
            <div class="show-preview">
                <div :style="previews.div" class="preview">
                    <img :src="previews.url" :style="previews.img">
                </div>

            </div>
        </div>
        <!-- 缩放旋转按钮 -->
        <div class="scope-btn">
            <el-button class="add-button" size='mini' icon="el-icon-zoom-in" @click="changeScale(1)"></el-button>
            <el-button class="add-button" size='mini' icon="el-icon-zoom-out" @click="changeScale(-1)"></el-button>
            <el-button class="add-button" size='mini' @click="rotateLeft">↺</el-button>
            <el-button class="add-button" size='mini' @click="rotateRight">↻</el-button>
        </div>
        <div class="footer-btn">
            <!-- 确认上传按钮 -->
            <div class="upload-btn">
                <el-button class="cancel-button" size='small' @click="onClose">{{this.$t('globalText.cancel')}}</el-button>
                <el-button class="ok-button" size='small' @click="uploadImg('')">{{this.$t('globalText.save')}}</el-button>
            </div>
        </div>
    </el-dialog>


</template>

<script>
    import {VueCropper} from 'vue-cropper'

    export default {
        data() {
            return {
                previews: {}, // 预览数据
                option: {
                    img: '', // 裁剪图片的地址  (默认：空)
                    size: 1, // 裁剪生成图片的质量  (默认:1)
                    full: false, // 是否输出原图比例的截图 选true生成的图片会非常大  (默认:false)
                    outputType: 'png', // 裁剪生成图片的格式  (默认:jpg)
                    canMove: true, // 上传图片是否可以移动  (默认:true)
                    original: false, // 上传图片按照原始比例渲染  (默认:false)
                    canMoveBox: false, // 截图框能否拖动  (默认:true)
                    autoCrop: true, // 是否默认生成截图框  (默认:false)
                    centerBox: false,
                    mode: 'contain',

                    fixedBox: true, // 固定截图框大小 不允许改变  (默认:false)

                },
                loading: true,
                downImg: '#',
                visible: false
            }
        },
        components: {VueCropper},
        props: ['imgFile', 'fixedNumber', 'align', 'value'],
        watch: {
            imgFile: function (val) {
                this.option.img = val;
                this.loading = true;
            },
            value(val) {
                this.visible = val;
            }

        },
        created() {
            if (this.align == 'horizontal') {
                this.option.autoCropWidth = 400;
                this.option.autoCropHeight = 250;
            } else {
                this.option.autoCropWidth = 400;
                this.option.autoCropHeight = 400;
            }
            this.visible = this.value;
        },
        methods: {
            changeScale(num) {
                // 图片缩放
                num = num || 1
                this.$refs.cropper.changeScale(num)
            },
            rotateLeft() {
                // 向左旋转
                this.$refs.cropper.rotateLeft()
            },
            rotateRight() {
                // 向右旋转
                this.$refs.cropper.rotateRight()
            },
            update() {
                // this.file = this.imgFile
                this.option.img = this.imgFile
            },
            realTime(data) {
                // 实时预览
                this.previews = data;
            },
            imgLoad(data) {
                console.log(data);
                this.loading = false;
                if (data == "error") {
                    let msg = this.$t('popUpMsg.drivers.failedToLoadImageFromCloud'); // "Failed to load image from cloud."
                    this.$customAlert(msg).then(() => {
                        this.onClose();
                    });
                }else{
                    this.setMode();
                }

            },
            setMode(){
                var imageWidth = this.$refs.cropper.trueWidth;
                var imageHeight = this.$refs.cropper.trueHeight;
                var cropBoxWHRate = this.option.autoCropWidth / this.option.autoCropHeight;
                if (imageWidth < imageHeight * cropBoxWHRate) {
                    this.option.mode = this.option.autoCropWidth + "px " + parseInt(this.option.autoCropWidth * imageHeight / imageWidth) + "px";
                } else {
                    this.option.mode = parseInt(this.option.autoCropHeight * imageWidth / imageHeight) + "px " + this.option.autoCropHeight + "px";
                }
            },

            uploadImg(type) {
                // 将剪裁好的图片回传给父组件
                event.preventDefault()
                let that = this
                if (type === 'blob') {
                    this.$refs.cropper.getCropBlob(data => {
                        that.$emit('upload', data)
                    })
                } else {
                    this.$refs.cropper.getCropData(data => {
                        that.$emit('upload', data)
                    })
                }
            },
            onClose() {
                this.visible = false;
                this.$emit('onClose')
            }
        },

    }
</script>
<style lang="stylus" scope>
    @import "~@/assets/stylus/color.styl"
    .cropper-content {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding 0 0
    }

    .cropper-content .cropper {
        width: 500px;
        height: 500px;
        border-radius 5px;
    }

    .cropper-view-box {
        outline: 1px solid $color_blue_second;
        outline-color: $color_blue_second;
    }

    .cropper-content .show-preview {
        height 500px;
        align-items: center
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        overflow: hidden;

    }

    .preview {
        overflow: hidden;
        border: 1px solid $color_gray_third;
        border-radius 5px;
    }

    .footer-btn {
        text-align right
        padding-right 10px;
        float right;
        margin-top 20px;
    }

    .scope-btn {
        float left
        width: 600px;
        margin-top 15px;
        margin-left 0;
        text-align center;
        button {
            margin-left 30px !important;
            font-size 18px;
            border 1px solid $color_gray_third
        }
    }

    .footer-btn .upload-btn {
        button {
            width 100px !important;
            margin-left 20px !important;
        }
    }

    .cropperDialog
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
        border-radius 5px !important;
        overflow-y auto

    .cropperDialog .el-dialog__header
        display none !important

    .cropperDialog .el-dialog__body
        padding 25px 15px 10px 15px
</style>
