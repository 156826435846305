<template>
    <div :style="{height: align == 'horizontal'?'105px':'120px'}" class="upload-content">

        <div class="upload-image" v-if="!hasFile" :class="{'vertical ':align == 'vertical'}">
            <a href="javascript:;" class="upload-area">
                <span class="el-icon-plus add-area"></span>
                <input type="file" class="file-upload" ref="fileBtn" @change="handleCrop" title=""
                       accept="image/*"/>
            </a>
        </div>

        <div v-else class="upload-image" @mouseenter="showDelBtn" @mouseleave="hideDelBtn"
             :class="{'vertical ':align == 'vertical'}">
            <img :src="filePath" ref="img" :class="{'img-vertical':align == 'vertical'}"/>
            <div class="upload-operate" v-if="isShowDelBtn">
                <i class="el-icon-zoom-in" @click="previewImage"></i>
                <span class="via-delete" @click="deleteImage"></span>
            </div>
        </div>
        <el-button v-if="showOtherFile" class="ok-button face-photo-button"  size="mini"
                   :disabled="otherFile == null"
                   @click="handleOtherFileUpload">{{$t('driver.useFaceIDPhoto')}}
        </el-button>

        <preview-dialog v-model="dialogVisible" :img-path="filePath" width="600px"></preview-dialog>
        <Cropper v-model="cropperModel" :img-file="corpperFile" ref="vueCropper" :align="align" @upload="uploadImg "
                 @onClose="beforeClose">
        </Cropper>


    </div>
</template>

<script>
    import Cropper from './Cropper'
    import PreviewDialog from "@/components/common/PreviewDialog";

    export default {
        name: "Upload",
        components: {
            PreviewDialog,
            Cropper
        },
        data() {
            return {
                filePath: null,
                otherFilePath: null,
                hideUpload: false,
                isShowDelBtn: false,
                dialogVisible: false,
                cropperModel: false,
                corpperFile: null

            };
        },
        props: {
            file: {type: String, default: null},
            align: {type: String, default: "horizontal"},
            showOtherFile: {type: Boolean, default: false},
            otherFile: {type: String, default: null},
        },
        watch: {
            file(newVal) {
                //  console.log(newVal);
                this.filePath = newVal
            },
            otherFile(newVal) {
                this.otherFilePath = newVal + "?v=" + new Date().getTime();
            }
        },
        computed: {
            hasFile: function () {
                //  console.log(this.filePath);
                return (this.filePath != null);
            }
        },
        methods: {
            uploadImg(data) {
                var uploadBase64 = data.replace(/\r|\n/g, '').replace('data:image/jgp;base64,', '').replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '');
                this.$emit("selectFile", uploadBase64);
                this.filePath = data;
                this.cropperModel = false;
                this.corpperFile = null;

            },
            handleCrop(e) {
                // 点击弹出剪裁框
                var _this = this;
                //上传图片
                let inputFile = e.target.files[0];
                _this.fileName = inputFile.name;
                if (inputFile.type.indexOf('image/') < 0) {
                    this.$customAlert('Please input image file.');
                    return;
                }
                this.cropperModel = true;
                const isLt2M = inputFile.size / 1024 / 1024 < 6;
                if (!isLt2M) {
                    this.$message({
                        message: this.$t('tips.imageSizeTips'),
                        type: 'warning'
                    });
                    return;
                }
                var reader = new FileReader();
                reader.onload = (e) => {
                    let data;
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        data = window.URL.createObjectURL(new Blob([e.target.result]))
                    } else {
                        data = e.target.result;
                    }
                    this.corpperFile = data;
                }
                // 转化为blob
                reader.readAsArrayBuffer(inputFile);
                this.$refs.fileBtn.value = null;
            },

            showDelBtn() {
                this.isShowDelBtn = true;
            },
            hideDelBtn() {
                this.isShowDelBtn = false;
            },
            deleteImage() {
                this.filePath = null;
                this.$emit("selectFile", null);
            },
            previewImage() {
                this.dialogVisible = true;
            },
            beforeClose() {
                this.corpperFile = null;
                this.cropperModel = false;
            },
            handleOtherFileUpload() {
                this.corpperFile = this.otherFilePath;
                this.cropperModel = true;
            }


        },
        created() {
            this.filePath = this.file;
        }
    }
</script>
<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .upload-content {
        display flex
        align-items center
    }

    .upload-content .upload-image {
        position: relative;
        float left
        display: inline-block;
        width: 168px;
        height: 105px;
        line-height: 105px;
        border: 1px dashed $color_gray_third;
        text-align: center;
        border-radius: 6px;
    }

    .upload-content .horizontal {
        width: 168px;
        height: 105px;

    }

    .upload-content .vertical {
        width: 120px;
        height: 120px;
        line-height: 120px;
    }

    .upload-content .upload-image .delete-icon {
        position: relative;
        top: -3px;
        left: -18px;
        font-size: 16px;
        cursor: pointer;
        display: inline;
    }

    .upload-content .upload-area {
        position: relative;
        display: inline-block;
        border: 1px dashed $color_gray_third;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: $color_black_second;
        text-decoration: none;
        text-indent: 0;
        font-size: 28px;
        text-align: center;
    }

    .upload-content .upload-area .file-upload {
        height: 100%;
        width 100%;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
    }

    .upload-content .upload-image .upload-operate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
        color: white;
        font-size: 18px;
        text-align: center;
        border-radius: 6px;
    }

    .upload-content .upload-image .upload-operate span, .upload-content .upload-image .upload-operate i {
        margin: 10px;
    }

    .upload-content .upload-image img {
        max-height: 105px;
        max-width: 168px;
        vertical-align: middle;
        border-radius: 6px;
    }

    .upload-content .img-vertical {
        max-height: 120px !important;
        max-width: 120px !important;
    }

    .upload-content .upload-area .file-upload {

    }

    .upload-content .upload-area:hover {
        border-color: $color_blue_second;
        text-decoration: none;
        color: $color_blue_second !important
    }

    .preview-image {
        object-fit: contain;
        vertical-align: middle;
    }

    .upload-content .face-photo-button {
        margin-left 20px;
        width auto;
    }

    .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
        color: $color_white;
        cursor: not-allowed;
        background-image: none;
        background-color: $color_black_third !important;
        border-color: $color_black_third;
        opacity: 1 !important;
    }
</style>
<style>
    .el-upload--picture-card {
        display: none;
    }



</style>
